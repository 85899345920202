<template>
  <div class="token">
    <h4 class="tokenTitle">
      <img width="55" :src="tokens[token.key as Tokens]?.toString()" :alt="token.key" />
      <span>{{ $t(tokensConfig[token.key]?.label || '', { count: 0 }) }}</span>
    </h4>
    <div class="tokenInfoBlocks">
      <div class="tokenInfoBlock">
        <div class="blockItem">
          <span class="heading"> {{ $t('appStatisticOwnedToken') }}: </span>
          <span class="value"> {{ format(token.balance, 0) }}</span>
        </div>
        <div v-if="showStats" class="blockItem">
          <span class="heading"> {{ $t('appStatisticRewardsEarned') }}: </span>
          <span class="value"> {{ format(token.earnedBalance, 0) }}</span>
        </div>
      </div>

      <div v-if="showStats" class="tokenInfoBlock">
        <div class="blockItem">
          <span class="heading"> {{ $t('storageMobileTotalPearlShare') }}: </span>
          <span class="value">{{ format(token.shared, 2) }}%</span>
        </div>
        <div
          class="blockItem"
          @click="
            () => {
              store.currentToken = token.key;
              emit('close');
            }
          "
        >
          <NuxtLink class="blockItem-stats-link" :href="`/dashboard?scrollToContainer=true`"
            >{{ $t('storageStatistics') }} <img src="/img/icons/arrowRight.svg" alt="arrowRight"
          /></NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, onBeforeMount } from 'vue';
import { BigNumber } from 'bignumber.js';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { Tokens, tokensConfig } from '~/utils/constants';
import tokens from '~/public/img/tokens';
import type { StorageItem } from '~/types/apiService';

// TODO: make it as reusable function
function format(value: string, decimals?: number): string {
  if (!value || value === '...') {
    return value;
  }
  return new BigNumber(value).toFormat(decimals);
}

const store = useMainStore();
const { address } = useWeb3ModalAccount();
const emit = defineEmits(['close']);

const props = defineProps<{
  token: StorageItem;
}>();

const showStats = computed(() => props.token.isSellable);

onBeforeMount(() => {
  return { address: address.value };
});
</script>

<style lang="scss" scoped>
.token {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  border-radius: 15px;
  padding: 10px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(0deg, #041a27 0%, #041a27 100%), #14232a;
}

.optionItem {
  color: #fff;
  font-family: Grenze, sans-serif;
  font-size: 32px;
  font-weight: 400;
}

.tokenTitle {
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  width: 110px;
  line-height: 1;

  @media screen and (max-width: 560px) {
    span {
      font-size: 13px;
    }
  }
}

.tokenInfoBlocks {
  display: flex;
  gap: 10px;
  width: calc(100% - 85px);
}

.tokenInfoBlock {
  min-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(0deg, #0d242f 0%, #0d242f 100%), linear-gradient(0deg, #10364a 0%, #10364a 100%), #14232a;

  .blockItem {
    display: flex;
    flex-direction: column;

    &-stats-link {
      margin-top: auto;
      border: 2px solid rgba(0, 0, 0, 0.2);
      border-color: #fff;
      border-radius: 48px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;

      img {
        height: 12px;
        width: 12px;
        margin-left: 8px;
      }
    }
  }
  .heading {
    color: #8e979c;
    font-size: 12px;
    font-weight: 500;
    font-family: Grenze, sans-serif;
    display: flex;
    line-height: 1;
  }

  .value {
    color: #fff;
    font-size: 32px;
    font-weight: 500;
    font-family: Grenze, sans-serif;
    line-height: 1;
    display: flex;
    align-items: center;

    @media screen and (max-width: 479px) {
      font-size: 24px;
    }
  }
}
</style>
