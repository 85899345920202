<template>
  <div class="container">
    <img class="infoIcon" width="24" src="/img/icons/ic_info.svg" alt="" @click="showInfoPopup = true" />
    <el-dialog
      v-model="showInfoPopup"
      :title="$t(modalView === SeasonModalView.jackpot ? 'seasonWhatIsJackpot' : 'seasonWhatIsSeason')"
      modal-class="modalSeasonInfo"
      :fullscreen="$device.isMobile"
      width="100%"
      class="tripleBlockInfo"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
    >
      <GeneralInfoModal
        v-if="modalView === SeasonModalView.jackpot"
        :description="$t('seasonInfoModalDescriptionJackpot')"
        :config="infoModalJackpot"
        @close="showInfoPopup = false"
      />

      <GeneralInfoModal
        v-else
        :description="$t('seasonInfoModalDescriptionJackpot')"
        :config="infoModal"
        @close="showInfoPopup = false"
      />
    </el-dialog>
    <p
      v-if="
        modalView !== SeasonModalView.end &&
        modalView !== SeasonModalView.rewards &&
        modalView !== SeasonModalView.jackpot
      "
      class="seasonDuration"
    >
      {{ $t('seasonEndsIn') }}:
      <vue-countdown v-slot="{ days, hours, minutes, seconds }" :time="end">
        <span>
          {{ BigNumber(days).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(days)}d` : '' }}
          {{ BigNumber(hours).isGreaterThan(0) ? `${formatToZeroPrefixedNumber(hours)}h` : '' }}
          {{ `${formatToZeroPrefixedNumber(minutes)}m` }}
          {{
            BigNumber(hours).isGreaterThan(0) ||
            (BigNumber(hours).isZero() && BigNumber(minutes).isZero()) ||
            BigNumber(seconds).isZero()
              ? ''
              : `${formatToZeroPrefixedNumber(seconds)}s`
          }}
        </span>
      </vue-countdown>
    </p>
    <p
      v-if="
        SEASON_MODAL_VIEW_CONFIG[modalView].descriptionKey &&
        !(modalView === SeasonModalView.jackpot && $device.isMobile)
      "
      class="seasonDescription"
    >
      {{
        $t(
          `${SEASON_MODAL_VIEW_CONFIG[modalView].descriptionKey}${![SeasonModalView.end, SeasonModalView.rewards, SeasonModalView.jackpot].includes(modalView) && isProdEnv ? currentSeasonId : ''}` ||
            ''
        )
      }}
    </p>

    <SeasonModalSwitcher
      v-if="
        modalView !== SeasonModalView.end &&
        modalView !== SeasonModalView.rewards &&
        modalView !== SeasonModalView.start
      "
      :modal-view="modalView"
      @update-modal-view="onUpdateModalView"
    />

    <p
      v-if="SEASON_MODAL_VIEW_CONFIG[modalView].contentTitleKey"
      :class="{ topOffset: modalView === SeasonModalView.start }"
      class="contentTitle"
    >
      {{ $t(SEASON_MODAL_VIEW_CONFIG[modalView].contentTitleKey || '') }}
    </p>

    <div
      v-if="modalView === SeasonModalView.burnTokens"
      class="burnWrapper"
      :class="{ 'new-year': store.isNewYearTheme }"
    >
      <UIModalInnerContentWrapper
        :style="
          $device.isMobile ? { padding: 0, border: 'none', background: 'none', height: '100%', maxHeight: '100%' } : {}
        "
      >
        <div class="burnCardsContainer">
          <SeasonBurnCard
            v-for="(token, index) of tokens"
            :key="index"
            :season-id="BigNumber(currentSeasonId).toNumber()"
            :season-token="token"
            :token-id="BigNumber(token?.tokenId || 0).toNumber()"
            :token-value="BigNumber(token?.tokenValue || 0).toNumber()"
          />
        </div>
      </UIModalInnerContentWrapper>
    </div>

    <div v-else-if="modalView === SeasonModalView.leaderboard" class="leaderboardWrapper">
      <SeasonLeaderboardList :is-jackpot="false" />
    </div>

    <div v-else-if="modalView === SeasonModalView.jackpot" class="jackpotBlock">
      <el-dialog
        v-model="isShowHistoricalLeaderboard"
        :fullscreen="$device.isMobile"
        :title="$t(`seasonName${BigNumber(activeIndex || 0).minus(1)}`)"
        :append-to-body="false"
        :center="true"
        class="historicalLeaderboardModal"
        @closed="activeIndex = null"
      >
        <ElIconArrowLeft
          style="position: absolute; cursor: pointer; height: 78px; top: 50%; left: -100px"
          :style="{ opacity: activeIndex && (activeIndex || 0) > 1 ? 1 : 0.5 }"
          @click="
            () => {
              if (activeIndex && (activeIndex || 0) !== 1) activeIndex -= 1;
            }
          "
        />
        <ElIconArrowRight
          style="position: absolute; cursor: pointer; height: 78px; top: 50%; right: -100px"
          :style="{ opacity: activeIndex && BigNumber(currentSeasonId).isGreaterThanOrEqualTo(activeIndex) ? 1 : 0.5 }"
          @click="
            () => {
              if (activeIndex && BigNumber(currentSeasonId).isGreaterThanOrEqualTo(activeIndex)) activeIndex += 1;
            }
          "
        />

        <p class="seasonDuration">
          {{ $t('seasonHolding') }}: {{ checkedHistoricalSeasonStartDate }} - {{ checkedHistoricalSeasonEndDate }}
        </p>
        <SeasonLeaderboardList
          style="padding-top: 10px"
          :is-jackpot="true"
          :checked-historical-season="
            BigNumber(activeIndex || 0)
              .minus(1)
              .toNumber()
          "
        />
      </el-dialog>

      <div class="jackpotWrapper">
        <SeasonJackpot />
      </div>

      <div class="seasonSwitcherWrapper">
        <UIBuildingButton
          v-if="$device.isMobile"
          :disabled="activeIndex === 1 || !activeIndex"
          mode="compact"
          icon="singleArrowLeft"
          class="slide-prev"
          :class="{
            'slide-disabled': activeIndex === 1
          }"
          @handle-click="
            () => {
              activeIndex = activeIndex! - 1;
              if ($device.isMobile && swiperInstance) {
                swiperInstance.slideTo(activeIndex - 1);
              } else {
                switchBtnsElements[activeIndex || 0].scrollIntoView({ behavior: 'smooth', inline: 'center' });
              }
            }
          "
        />

        <div class="seasonSwitcherItemsContainer">
          <div
            style="
              height: 20%;
              background: #1e353e;
              width: 98%;
              position: absolute;
              top: 50%;
              left: 10px;
              z-index: -1;
              transform: translateY(-50%);
            "
          >
            <div
              style="
                height: 100%;
                background: linear-gradient(
                  to right,
                  rgba(96, 255, 124, 1) 0%,
                  rgba(96, 255, 124, 1) 90%,
                  rgba(96, 255, 124, 0) 100%
                );
                max-width: 100%;
              "
              :style="{
                width: $device.isMobile
                  ? `${seasonsProgressMobileIndicator}%`
                  : `calc(${BigNumber(currentSeasonId).dividedBy(blockchain.seasonsCountInCurrentYear).multipliedBy(100).toNumber()}% + 50px)`
              }"
            ></div>
          </div>
          <div v-if="$device.isDesktop" class="seasonSwitcherItems">
            <div
              v-for="i in blockchain.seasonsCountInCurrentYear"
              :key="i"
              ref="switchBtnsElements"
              class="seasonSwitcherBtn"
              :class="{
                goldColor: BigNumber(i).isLessThanOrEqualTo(BigNumber(currentSeasonId).plus(1)),
                checkedSeason: activeIndex === i
              }"
              :style="{ fontSize: locale !== 'ru' ? '24px' : '16px' }"
              @click="
                () => {
                  if (BigNumber(currentSeasonId).plus(1).isLessThan(i)) return;
                  activeIndex = i;
                  isShowHistoricalLeaderboard = true;
                }
              "
            >
              <span :style="{ transform: locale !== 'ru' ? 'translateY(-3px)' : 'translateY(0)' }">{{ i }}</span>
            </div>
          </div>
          <swiper
            v-else
            :slides-per-view="3"
            :initial-slide="BigNumber(currentSeasonId).toNumber()"
            :space-between="0"
            :height="90"
            :centered-slides="true"
            class="seasonSwitcherMobileSwiper"
            @swiper="(swiper) => (swiperInstance = swiper)"
          >
            <swiper-slide v-for="i in blockchain.seasonsCountInCurrentYear" :key="i"
              ><div
                ref="switchBtnsElements"
                class="seasonSwitcherBtn"
                :class="{
                  goldColor: BigNumber(i).isLessThanOrEqualTo(BigNumber(currentSeasonId).plus(1)),
                  checkedSeason: activeIndex === i
                }"
                :style="{ fontSize: locale !== 'ru' ? '24px' : '16px' }"
                @click="
                  () => {
                    if (BigNumber(currentSeasonId).plus(1).isLessThan(i)) return;
                    activeIndex = i;
                    isShowHistoricalLeaderboard = true;
                  }
                "
              >
                <span :style="{ transform: locale !== 'ru' ? 'translateY(-3px)' : 'translateY(0)' }">{{ i }}</span>
              </div></swiper-slide
            >
          </swiper>
        </div>

        <UIBuildingButton
          v-if="$device.isMobile"
          mode="compact"
          icon="singleArrowRight"
          class="slide-next"
          :disabled="(!!activeIndex && BigNumber(currentSeasonId).plus(1).isEqualTo(activeIndex)) || !activeIndex"
          :class="{
            'slide-disabled': activeIndex && BigNumber(currentSeasonId).plus(1).isEqualTo(activeIndex)
          }"
          @handle-click="
            () => {
              activeIndex = (activeIndex || 0) + 1;
              if ($device.isMobile && swiperInstance) {
                swiperInstance.slideTo(activeIndex - 1);
              } else {
                switchBtnsElements[activeIndex || 0].scrollIntoView({ behavior: 'smooth', inline: 'center' });
              }
            }
          "
        />
      </div>
    </div>

    <div v-else-if="modalView === SeasonModalView.start" class="startWrapper">
      <SeasonStart @update-modal-view="onUpdateModalView" />
    </div>

    <div v-else-if="modalView === SeasonModalView.end || modalView === SeasonModalView.rewards" class="endWrapper">
      <SeasonEnd
        @update-modal-view="onUpdateModalView"
        @close="
          () => {
            emit('close');
            checkedSeason = BigNumber(currentSeasonId).minus(1).toNumber();
          }
        "
        @update-checked-season="
          (value: number) => {
            checkedSeason = value;
          }
        "
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SeasonModalView, type TSeasonConfig, type TSeasonConfigItem } from '~/types/season';
import { SEASON_MODAL_VIEW_CONFIG } from '~/utils/constants';
import { BigNumber } from 'bignumber.js';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { formatEther } from 'ethers';
import type { TNullable } from '~/types/common';
import { infoModal, infoModalJackpot } from '../../utils/constants/infoModal';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperType } from 'swiper';
import VueCountdown from '@chenfengyuan/vue-countdown';

import 'swiper/css';
import 'swiper/css/effect-coverflow';

const config = useRuntimeConfig();
const isProdEnv = computed(() => config?.public.env === 'prod');
const { isMobile } = useDevice();

const { blockchain } = useEnvs();
const { getInterface, getInterfaceDecoded, getContractReadOnly, getMulticallResult, getContract } = useAbiAccess();
const { address } = useWeb3ModalAccount();
const { t, locale } = useI18n();
const store = useMainStore();
const swiperInstance = ref<SwiperType>();

const switchBtnsElements = ref<HTMLElement[]>([]);
const activeIndex = ref<TNullable<number>>(null);
const isShowHistoricalLeaderboard = ref(false);

const seasonStartClicked = useCookie(`seasonStartClicked:${address.value}`);
const props = defineProps<{ view?: TNullable<SeasonModalView> }>();

const emit = defineEmits<{
  close: [];
  updateModalHeader: [value: string];
}>();

const seasonContract = await getContractReadOnly('season', blockchain.contracts.season);

const currentSeasonId = await seasonContract?.currentSeason();
const currentSeasonYear = await seasonContract.currentSeasonYear();

provide('currentSeasonYear', currentSeasonYear);

const checkedSeason = ref(BigNumber(currentSeasonId).toNumber());

const { data } = useNuxtData<TSeasonConfig>('seasonsConfig');

const seasonsProgressMobileIndicator = computed(() => {
  if (BigNumber((swiperInstance.value?.activeIndex || 0) + 1).isEqualTo(BigNumber(currentSeasonId).plus(1))) {
    return 60;
  }
  if (BigNumber(swiperInstance.value?.activeIndex || 0).isEqualTo(BigNumber(currentSeasonId).plus(1))) {
    return 40;
  }
  if (
    BigNumber((swiperInstance.value?.activeIndex || 0) - 1).isGreaterThanOrEqualTo(BigNumber(currentSeasonId).plus(1))
  ) {
    return 0;
  }
  if (BigNumber(swiperInstance.value?.activeIndex || 0).isLessThan(BigNumber(currentSeasonId).plus(1))) {
    return 98;
  }
  return 100;
});

watch(
  () => switchBtnsElements.value?.length,
  (newLength) => {
    if (newLength && !isMobile) {
      switchBtnsElements.value[BigNumber(currentSeasonId).toNumber()]?.scrollIntoView({ inline: 'center' });
    }
  }
);
watch(
  [address, () => data.value?.config?.length],
  async (newDataLength) => {
    if (!data.value || !newDataLength) return;

    const erc20 = data.value.config.filter(
      (token: TSeasonConfigItem) => tokenMetaData(blockchain.contracts, token.tokenAddress)?.interface === 'erc20'
    );

    const erc721 = data.value.config.filter(
      (token: TSeasonConfigItem) => tokenMetaData(blockchain.contracts, token.tokenAddress)?.interface === 'erc721'
    );

    const erc20SeasonPoints = await getErc20SeasonPoints(erc20);
    const erc721SeasonPoints = await getErc721SeasonPonts(erc721);
    const mergedTokens = [...erc20SeasonPoints, ...erc721SeasonPoints];

    const sorted = mergedTokens.sort((a, b) =>
      b.seasonPoints !== a.seasonPoints ? b.seasonPoints - a.seasonPoints : b.rate - a.rate
    );

    tokens.value = sorted;
  },
  { immediate: true }
);

const seasonEndDate = inject<ComputedRef<Date>>('seasonEndDate');
const showSeasonModal = inject<ComputedRef<boolean>>('showSeasonModal');
const ended = inject<Ref<boolean, boolean>>('ended');

const end = computed(() => seasonEndDate?.value?.getTime()! - Date.now() || 0);

const checkedHistoricalSeasonStartDate = asyncComputed(async () => {
  const seasonStart = BigNumber(
    await seasonContract?.getSeasonStartAt(
      BigNumber(activeIndex.value || 0)
        .minus(1)
        .toNumber()
    )
  )
    .multipliedBy(1000)
    .toNumber();
  return useDateFormat(() => new Date(seasonStart), 'DD.MM');
});
const checkedHistoricalSeasonEndDate = asyncComputed(async () => {
  const seasonEnd = BigNumber(await seasonContract?.getSeasonStartAt(BigNumber(activeIndex.value || 0).toNumber()))
    .multipliedBy(1000)
    .toNumber();
  return useDateFormat(() => new Date(seasonEnd), 'DD.MM');
});

const tokens = ref<TSeasonConfigItem[]>([]);
const modalView = ref(props.view || (seasonStartClicked.value ? SeasonModalView.burnTokens : SeasonModalView.start));
const showInfoPopup = ref(false);

watch(
  ended!,
  (newValue) => {
    if (newValue) modalView.value = SeasonModalView.end;
  },
  { immediate: true }
);

watch(
  () => props.view,
  (newView) => {
    if (newView) {
      modalView.value = newView;
    }
  },
  { immediate: true }
);

watch(
  [() => showSeasonModal?.value, () => ended?.value],
  ([newShow, newEnded]) => {
    if (!newShow && !newEnded) {
      modalView.value = SeasonModalView.burnTokens;
    } else if (newEnded) {
      modalView.value = SeasonModalView.end;
    }
  },
  { immediate: true }
);

watch(
  [modalView, checkedSeason],
  ([newView, newCheckedSeason]) => {
    switch (newView) {
      case SeasonModalView.burnTokens:
      case SeasonModalView.start:
      case SeasonModalView.leaderboard:
      case SeasonModalView.end:
        emit('updateModalHeader', t(`seasonName${currentSeasonId}`));
        break;
      case SeasonModalView.rewards:
        emit('updateModalHeader', `${t('coreRewardStatus')}: ${t(`seasonName${newCheckedSeason}`)}`);
        break;
      case SeasonModalView.jackpot:
        emit('updateModalHeader', t(`jackpot`));
        break;
      default:
        break;
    }
  },
  { immediate: true }
);
provide('currentSeasonId', currentSeasonId);
provide('modalView', modalView);

const onUpdateModalView = (view: SeasonModalView) => {
  modalView.value = view;
};

async function getErc20SeasonPoints(erc20: TSeasonConfigItem[]) {
  const erc20Addresses = erc20.map((token) => token.tokenAddress);
  const multicallResult = await getMulticallResult('erc20', erc20Addresses, 'balanceOf');
  const erc20Contracts = multicallResult
    .map((multicallElem: any, index: number) => {
      const balance = formatEther(BigInt(multicallElem[1]));
      const bigNumberBalance = BigNumber(Math.floor(Number(balance)));

      const seasonPoints = bigNumberBalance
        .minus(bigNumberBalance.toNumber() % erc20[index].tokenMultiplier)
        .dividedBy(erc20[index].tokenMultiplier || 1)
        .multipliedBy(erc20[index].pointsMultiplier)
        .integerValue(BigNumber.ROUND_FLOOR)
        .decimalPlaces(2)
        .toNumber();

      return {
        ...erc20[index],
        balance,
        seasonPoints,
        rate: erc20[index].pointsMultiplier
      };
    })
    .flat();

  return erc20Contracts;
}

async function getErc721SeasonPonts(erc721: TSeasonConfigItem[]) {
  const erc721Interface = getInterface('erc721');
  const multicallContract = await getContract('multicall', blockchain.multicallAddress);
  const erc721Addresses = erc721.map((token) => token.tokenAddress);

  let multicallResult = await getMulticallResult('erc721', erc721Addresses, 'getTokenIds');

  const erc721Ids: number[][] = multicallResult.map((elem: any) => getInterfaceDecoded('erc721', elem[1]));
  const tokenAddresses: any = erc721Ids
    .map((tokenIdsArray: any[], tokenIndex: number) => {
      return tokenIdsArray[0].map((tokenId: any) => [
        erc721[tokenIndex].tokenAddress,
        erc721Interface.encodeFunctionData('getTokenValue', [tokenId])
      ]);
    })
    .flat();
  multicallResult = await multicallContract?.tryAggregate.staticCall(true, tokenAddresses);

  const erc721HashMap = Object.fromEntries(erc721.map((item) => [item.tokenAddress, item]));
  const erc721Contacts = tokenAddresses.map((tokenAddress: any[], index: number) => {
    const tokenId = erc721Interface.decodeFunctionData('getTokenValue', tokenAddress[1])[0];
    const tokenValue = Number(multicallResult[index][1]);
    const { pointsMultiplier } = erc721HashMap[tokenAddress[0]];
    const seasonPoints = BigNumber(pointsMultiplier).multipliedBy(tokenValue).toString();

    return { ...erc721HashMap[tokenAddress[0]], seasonPoints, tokenId, tokenValue, rate: seasonPoints };
  });

  return erc721Contacts.flat();
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  font-family: Grenze, sans-serif;

  @media screen and (max-width: 767px) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .infoIcon {
    position: absolute;
    top: 21px;
    cursor: pointer;
  }

  .seasonDuration,
  .seasonDescription,
  .contentTitle {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  .seasonDescription {
    margin: 0 32px;
  }

  .topOffset {
    margin-top: 40px;
  }

  .contentTitle {
    color: #fff;
    font-size: 24px;
    margin-bottom: 1rem;

    @media screen and (max-width: 479px) {
      margin-top: 20px;
      margin-bottom: 8px;
    }
  }

  .seasonDuration {
    color: #88e1ff;
    font-family: Eczar, sans-serif;
  }

  .seasonDescription {
    color: rgba(255, 255, 255, 0.8);
    width: 80%;
    margin: 0 auto;
  }

  .jackpotWrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    gap: 20px;
    margin-top: 20px;

    @media screen and (max-width: 560px) {
      flex-direction: column;
      gap: 0;
      width: 100vw;
      transform: translateX(-16px);
      max-height: calc(100dvh - 230px);
      margin-top: 0;
    }
  }

  .seasonSwitcherWrapper {
    width: 100%;
    isolation: isolate;
    position: relative;

    z-index: 9999;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 560px) {
      max-width: 100%;
    }
  }

  .seasonSwitcherItemsContainer {
    overflow: auto;
    scrollbar-width: none;
    width: 100%;
    padding: 8px;
  }

  .seasonSwitcherItems {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 560px) {
      gap: 20px;
      padding: 8px 0;
    }
  }

  .seasonSwitcherBtn {
    width: 26px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    line-height: 1;
    font-family: Grenze, sans-serif;
    border-radius: 50%;
    cursor: pointer;
    background: linear-gradient(to top, #070c0e 0%, #1d4350 100%);
    color: rgba(255, 255, 255, 0.3);
    aspect-ratio: 1/1;
    pointer-events: none;

    @media screen and (max-width: 560px) {
      width: 43px;
      font-size: 30px;
      flex-shrink: 0;
    }
  }

  .goldColor {
    color: #eea92e;
    border: solid 1px rgba(134, 121, 39, 1);
    pointer-events: all;

    @media (hover: hover) {
      &:hover {
        color: rgba(255, 255, 255, 1);
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: radial-gradient(circle at 50% 100%, rgba(0, 131, 173, 1), rgba(0, 0, 0, 0) 50%);
          border-radius: 50%;
          z-index: 0;
        }
      }
    }
  }

  .checkedSeason {
    box-shadow: 0px 0px 8px #eea92e;
    background: linear-gradient(to bottom, #0199ae 0%, #002537 100%);
    transform: scale(1.1);
  }

  .burnCardsContainer {
    display: grid;
    gap: 25px;
    width: 100%;
    grid-template-columns: repeat(5, minmax(0, 1fr));

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  }

  .endWrapper {
    @media screen and (max-width: 767px) {
      height: 100%;
    }
  }

  .leaderboardWrapper {
    @media screen and (max-width: 767px) {
      max-width: 100vw;
      margin: 20px -16px;
    }
  }

  .jackpotBlock {
    width: 100%;
  }
}
</style>

<style lang="scss">
.jackpotBlock {
  .el-overlay {
    background-color: rgba(0, 0, 0, 0.85);
  }
  width: 100%;
}
.el-dialog.seasonModal,
.el-dialog.historicalLeaderboardModal {
  overflow: visible;
  margin: 0;
  position: fixed;
  left: 50%;
  width: 100%;
}

.el-dialog.historicalLeaderboardModal {
  max-width: 70%;
  top: 5%;
  transform: translateX(-50%);
  height: calc(95% - 65px);

  @media screen and (max-width: 560px) {
    top: 0;
    height: calc(100dvh - 50px);
    max-width: 100%;
  }
}

.el-dialog.seasonModal {
  max-width: 905px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url('~/public/img/seasonBg6.png');
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;

  &:after {
    content: '';
    display: block;
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 27, 37, 0.4);
  }
  &.new-year {
    background-image: url('~/public/img/newYear/bg-2.png');
  }

  &.minHeight {
    min-height: 60%;
    @media screen and (max-width: 560px) {
      max-height: 100dvh;
    }
  }

  .el-dialog__title {
    color: #fff;
    padding-right: 0;
  }

  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;

    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;

    @media screen and (max-width: 767px) {
      max-height: 93%;
    }
    .contentTitle {
      color: #fff;
    }
  }

  .modalSeasonInfo {
    overflow: hidden;
    z-index: 99999 !important;
  }
}

.el-dialog.seasonModal.minContentWidth {
  background-image: none;
}

.el-dialog.seasonModal.seasonModalMobile.rewards {
  .el-dialog__body {
    @media screen and (max-width: 767px) {
      max-height: unset;
      height: 100%;
    }
  }
}

.el-dialog.seasonModal.ru-locale {
  @media screen and (max-width: 479px) {
    .seasonModalSwitcherContainer {
      min-height: 55px;
    }
  }
}

.seasonSwitcherMobileSwiper {
  &.swiper {
    padding: 8px 0;
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
}
</style>
