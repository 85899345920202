<template>
  <el-dialog
    v-model="shouldShowModal"
    class="crafting"
    :class="{ 'ru-locale': $i18n.locale === 'ru' }"
    :fullscreen="isMobile"
    :title="$t('warehouse')"
    :append-to-body="true"
    :center="true"
  >
    <div v-loading="loadingNft">
      <p class="crafting-description">{{ $t(props.description || '') }}</p>
      <StorageMobile
        v-if="($device.isMobile || $device.isTablet) && storageItems"
        :tokens="storageItems"
        :nft-tokens="nftTokens"
      />
      <StorageDesktop v-else-if="!$device.isMobile && storageItems" :tokens="storageItems" :nft-tokens="nftTokens" />
    </div>
  </el-dialog>
</template>
<script lang="ts" setup>
import { computed, watch } from 'vue';
import { apiUrls } from '~/utils/constants';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import type { BuildingProps } from '~/types/crafting';
import type { TNullable } from '~/types/common';
import type { TokenDescription } from '~/types/token';
import { tokensConfig } from '~/utils/constants';
import type { ContractAddresses, ContractAddressesItem } from '~/types/contractAddresses';
import { formatEther } from 'ethers';
import type { ITokenModel, StorageItem } from '~/types/apiService';
import { BigNumber } from 'bignumber.js';

type TokenMap = Record<string, string>;
type NftMetadata = { name: string; image_url: string };

const { apiUrl, blockchain } = useEnvs();
const { isMobile } = useDevice();
const { address } = useWeb3ModalAccount();
const { getInterface, getInterfaceDecoded, getMulticallResult, getContract } = useAbiAccess();
const storageItems = ref<StorageItem[]>([] as StorageItem[]);
const nftTokens = ref<TokenDescription[]>([] as TokenDescription[]);
const loadingNft = ref(false);

const props = defineProps<{
  selectedBuilding: TNullable<BuildingProps>;
  isStorageSelected: Boolean;
  description: string;
}>();

const { data: tokensData, refresh: refreshTokens } = useFetch<ITokenModel[]>(apiUrls.token.tokens, {
  baseURL: apiUrl,
  transform: (data) => {
    return data.sort((a, b) => a.displayOrder - b.displayOrder);
  }
});

const shouldShowModal = computed(() => !!(props.isStorageSelected && tokensData?.value));
const nonNftTokens = computed(() => tokensData.value && tokensData.value.filter((token) => !token.isNft));

function getErc20Sellables(): [ITokenModel[], string[]] {
  if (!nonNftTokens.value) return [[], []];

  const erc20Sellable = nonNftTokens.value.filter((token) => token.isSellable);
  const erc20sellableAddresses = erc20Sellable.map((token) => token.tokenContractAddress);

  return [erc20Sellable, erc20sellableAddresses];
}

async function getTokenBalances(nonNftTokens: ITokenModel[]): Promise<BigNumber[]> {
  const erc20Addresses = nonNftTokens.map((token: ITokenModel) => token.tokenContractAddress);
  const multicallBalanceOfResult = await getMulticallResult('erc20', erc20Addresses, 'balanceOf');
  const tokenAvailableBalances = multicallBalanceOfResult.map((multicallElem: [boolean, string]) => {
    const balance = formatEther(BigInt(multicallElem[1]));
    return BigNumber(Number(balance));
  });

  return tokenAvailableBalances;
}

async function getShared(): Promise<TokenMap> {
  const [, erc20sellableAddresses] = getErc20Sellables();

  const multicallTotalStakeResult = await getMulticallResult('mine', erc20sellableAddresses, 'totalSupply', []);
  const totalStakes = multicallTotalStakeResult.map((multicallElem: [boolean, string]) => {
    const stake = formatEther(BigInt(multicallElem[1]));
    return BigNumber(Number(stake));
  });

  const multicallTokenTotalStakesResult = await getMulticallResult('mine', erc20sellableAddresses, 'balanceOf');
  const shares = multicallTokenTotalStakesResult.map((multicallElem: [boolean, string], index: number) => {
    const tokenTotalStake = formatEther(BigInt(multicallElem[1]));

    const share = totalStakes[index].isZero()
      ? '0'
      : BigNumber(Number(tokenTotalStake)).dividedBy(totalStakes[index]).multipliedBy(100).toFixed(4);

    return {
      [erc20sellableAddresses[index]]: share
    };
  });
  const sharesHashMap: TokenMap = Object.assign({}, ...shares);

  return sharesHashMap;
}

async function getEarnedBalance(): Promise<TokenMap> {
  const mineInterface = getInterface('mine');
  const pearlYieldInterface = getInterface('pearlYield');
  const [erc20Sellable, erc20sellableAddresses] = getErc20Sellables();
  const multicallContract = await getContract('multicall', blockchain.multicallAddress);
  const multicallData = erc20Sellable.map((token: ITokenModel) => {
    const isLicenseToken = blockchain.contracts[token.key]?.addresses?.mine;
    const calldata = isLicenseToken
      ? mineInterface.encodeFunctionData('mined', [address.value])
      : pearlYieldInterface.encodeFunctionData('earned', [address.value]);

    const multicallAddress = isLicenseToken ? isLicenseToken : blockchain.contracts[token.key]?.addresses?.yield;
    return [multicallAddress, calldata];
  });

  const multicallTotalEarnedResult = await multicallContract?.tryAggregate.staticCall(true, multicallData);
  const totalEarned = multicallTotalEarnedResult.map((multicallElem: [boolean, string], index: number) => {
    const isLicenseToken = blockchain.contracts[erc20Sellable[index].key]?.addresses?.mine;

    const earnedBalance = isLicenseToken
      ? BigNumber(formatEther(mineInterface.decodeFunctionResult('mined', multicallElem[1])[0]))
      : BigNumber(Number(formatEther(BigInt(multicallElem[1]))));
    return {
      [erc20sellableAddresses[index]]: earnedBalance
    };
  });
  const totalEarnedHashMap: TokenMap = Object.assign({}, ...totalEarned);

  return totalEarnedHashMap;
}

watch(
  [nonNftTokens],
  async () => {
    if (!address.value || !nonNftTokens.value) return;

    const tokenAvailableBalances = await getTokenBalances(nonNftTokens.value);
    const sharesHashMap = await getShared();
    const totalEarnedHashMap = await getEarnedBalance();

    storageItems.value = nonNftTokens.value.map((token: ITokenModel, index: number): StorageItem => {
      return {
        ...token,
        balance: tokenAvailableBalances[index].toString(),
        shared: sharesHashMap[token.tokenContractAddress] || '0',
        earnedBalance: totalEarnedHashMap[token.tokenContractAddress] || '0'
      };
    });
  },
  { immediate: true }
);

const getNftData = async () => {
  loadingNft.value = true;
  const erc721Tokens = Object.entries(tokensConfig)
    .filter(([, value]) => value.interface === 'erc721')
    .map(([key, value]) => ({ token: key, ...value }));

  const multicallContract = await getContract('multicall', blockchain.multicallAddress);
  const erc721Interface = getInterface('erc721');
  const erc721TokensAddresses = erc721Tokens.map((tokenInfo) => {
    const tokenName = tokenInfo.token as unknown as keyof ContractAddresses;
    const tokenAddress = (blockchain.contracts[tokenName] as ContractAddressesItem).addresses.contract;
    return tokenAddress;
  });
  let multicallResult: [boolean, string][] = await getMulticallResult('erc721', erc721TokensAddresses, 'getTokenIds');
  const erc721Ids: number[][] = multicallResult.map((elem) => getInterfaceDecoded('erc721', elem[1]));
  const tokenAddresses: [string, string][] = erc721Ids
    .map((tokenIdsArray: any[], tokenIndex: number) => {
      return tokenIdsArray[0].map((tokenId: BigInt) => [
        erc721TokensAddresses[tokenIndex],
        erc721Interface.encodeFunctionData('tokenURI', [tokenId])
      ]);
    })
    .flat();

  multicallResult = await multicallContract?.tryAggregate.staticCall(true, tokenAddresses);
  const allNftUris = multicallResult.map(
    (multicallElem: [boolean, string]) => erc721Interface.decodeFunctionResult('tokenURI', multicallElem[1])[0]
  );
  const nftMetadataArray: NftMetadata[] = await Promise.all(allNftUris.map((uri: string) => $fetch<NftMetadata>(uri)));

  nftTokens.value = nftMetadataArray.map((nft: NftMetadata) => {
    const { image_url, name } = nft;
    return {
      name,
      imageUrl: image_url,
      description: `${name}Description`
    };
  });

  loadingNft.value = false;
};

watch(
  [address],
  async () => {
    await getNftData();

    await refreshTokens();
  },
  {
    immediate: true
  }
);
</script>
