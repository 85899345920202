<template>
  <div class="storage-container">
    <div class="items">
      <StorageListItem v-for="token of tokens" :key="token.id" :token="token" @close="emit('close')" />
      <StorageNftListItem
        v-for="nftToken of props.nftTokens"
        :key="nftToken.imageUrl"
        :nft-token="nftToken"
        @close="emit('close')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import type { StorageItem } from '~/types/apiService';
import type { TokenDescription } from '~/types/token';

const emit = defineEmits(['close']);

const props = defineProps<{
  tokens: StorageItem[];
  nftTokens: TokenDescription[];
}>();
</script>

<style lang="scss" scoped>
.storage-container {
  margin-top: 30px;
}

.card.storage {
  max-height: calc(100vh - 250px);
  opacity: 1;
  padding: 25px;
  transition: 300ms ease all;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  font-size: 32px;
  color: #fff;
}

.items {
  grid-row-gap: 20px;
  grid-column-gap: 30px;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-auto-columns: 1fr;
  display: grid;
  border-radius: 10px;
  border: 1px solid #1e353e;
  background: #0d242f;
  padding: 28px;
  width: 100%;
  justify-content: center;
  overflow: auto;
}
</style>

<!-- popper custom styles -->
<style>
.customPopper {
  padding: 20px !important;
  border-radius: 15px !important;
  border: 1px solid #2f2f2f !important;
  background: rgba(0, 0, 0, 0.85) !important;
}
</style>
