<template>
  <div class="container">
    <div class="imgContainer">
      <img :src="props.tokenMedia" alt="token image" />
    </div>
    <div class="infoContainer">
      <p width="84" class="ownedValue">{{ props.ownedValue }}</p>
      <p class="earnedValue">{{ props.earnedValue }}</p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue';

const props = defineProps<{
  tokenMedia: string;
  ownedValue?: string;
  earnedValue?: string;
}>();
</script>
<style lang="scss" scoped>
.container {
  border-radius: 12px;
  border: 1px solid #1e353e;
  background: radial-gradient(92.39% 62.21% at 84.01% 85.82%, #040f14 0%, #081b25 100%);
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  width: 100%;
  min-width: 133px;
  height: 170px;
  display: flex;
  flex: 1 1 0;
  align-items: center;
  flex-direction: column;
  line-height: 1;

  .imgContainer {
    width: 84px;
    max-height: 84px;
    border-radius: 12px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 62px;
  }

  &:hover:not(.selected) {
    background: radial-gradient(102.4% 102.35% at 57.35% 110.94%, #0083ad 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:not(.selected) {
    cursor: pointer;
  }
}

.ownedValue {
  color: #90989d;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  font-family: Grenze, sans-serif;
}

.earnedValue {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  font-family: Grenze, sans-serif;
}
</style>
