<template>
  <el-tooltip ref="refPopper" :trigger="'click'" popper-class="customPopper" effect="dark" :show-after="200">
    <template #content>
      <div ref="refContent" class="tokenTooltip" @mouseleave="handleMouseLeave">
        <h4 class="tooltipTitle">
          <img width="50" :src="tokens[token.key as Tokens]?.toString()" :alt="token.key" />
          {{ $t(tokensConfig[token.key].label, { count: 0 }) }}
        </h4>
        <div class="tooltipInfoBlocks">
          <div class="tooltipInfoBlock">
            <div class="blockItem">
              <span class="heading"> {{ $t('appStatisticOwnedToken') }}: </span>
              <span class="value">{{ format(token.balance) }}</span>
            </div>
            <div v-if="token.isSellable" class="blockItem">
              <span class="heading"> {{ $t('appStatisticRewardsEarned') }}: </span>
              <span class="value">{{ format(token.earnedBalance) }}</span>
            </div>
          </div>

          <div v-if="token.isSellable" class="tooltipInfoBlock">
            <div class="blockItem">
              <span class="heading">{{ $t('storageMobileTotalPearlShare') }}:</span>
              <span class="value">{{ format(token.shared, 2) }}%</span>
            </div>
            <div class="blockItem" @click="handleClick">
              <NuxtLink class="blockItem-stats-link" :href="`/dashboard?scrollToContainer=true`"
                >{{ $t('storageStatistics') }} <img src="/img/icons/arrowRight.svg" alt="arrowRight"
              /></NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </template>
    <storage-item
      :token-media="tokens[token.key as Tokens]?.toString()"
      :owned-value="format(token.balance)"
      :earned-value="format(token.earnedBalance)"
      @mouseenter="handleMouseEnter"
    />
  </el-tooltip>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { BigNumber } from 'bignumber.js';
import { Tokens, tokensConfig } from '~/utils/constants';
import type { StorageItem } from '~/types/apiService';
import tokens from '~/public/img/tokens';

const store = useMainStore();

const props = defineProps<{
  token: StorageItem;
}>();

const isTooltipVisible = ref<boolean>(false);
const refPopper = ref();
const refContent = ref();

const handleClick = () => {
  store.currentToken = props.token.key;
  emit('close');
  isTooltipVisible.value = false;
  refPopper.value.hide();
};

const handleMouseEnter = () => {
  isTooltipVisible.value = true;
};

const handleMouseLeave = () => {
  isTooltipVisible.value = false;
};

// TODO: make it as reusable function
function format(value: string, decimals: number = 2): string {
  if (!value || value === '...') {
    return value;
  }
  return new BigNumber(value).toFormat(decimals);
}

const emit = defineEmits(['close']);
</script>

<style lang="scss" scoped>
.tokenTooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tooltipTitle {
  display: flex;
  gap: 10px;
  color: #88e1ff;
  align-items: center;

  img {
    flex-shrink: 0;
  }
}

.tooltipInfoBlocks {
  display: flex;
  gap: 10px;
  width: 100%;
}

.tooltipInfoBlock {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 25px;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(0deg, #0d242f 0%, #0d242f 100%), linear-gradient(0deg, #10364a 0%, #10364a 100%), #14232a;

  .blockItem {
    display: flex;
    flex-direction: column;

    &-stats-link {
      margin-top: auto;
      border: 2px solid rgba(0, 0, 0, 0.2);
      border-color: #fff;
      border-radius: 48px;
      font-size: 18px;
      line-height: 40px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 25px;

      img {
        height: 18px;
        width: 18px;
        margin-left: 8px;
      }
    }
  }
  .heading {
    color: #8e979c;
    font-size: 20px;
    font-family: Grenze, sans-serif;
    line-height: 40px;
    font-weight: 500;
    display: flex;
    align-items: center;

    img {
      flex-shrink: 0;
      margin-left: -8px;
    }
  }

  .value {
    color: #fff;
    font-family: Grenze, sans-serif;
    font-size: 32px;
    font-weight: 500;
  }
}
</style>

<!-- popper custom styles -->
<style>
.customPopper {
  padding: 20px !important;
  border-radius: 15px !important;
  border: 1px solid #2f2f2f !important;
  background: rgba(0, 0, 0, 0.75) !important;
}

.el-popper__arrow {
  display: none;
}
</style>
