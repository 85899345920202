<template>
  <div
    ref="el"
    class="leaderboardItem"
    :class="{ ownPositionBG: own }"
    :style="{ order: position }"
    @click="ownPositionClickHandler"
  >
    <p class="address">
      <span style="width: 45px">{{ position }}</span>
      <el-avatar class="avatar-primary" :src="userScores?.avatarUrl" :size="30" />
      <span class="user-name" :class="{ ownPositionRow: own, 'ru-locale': isUserNameRuLocale }">
        <span>{{ userName }}</span>
      </span>
    </p>

    <div class="score">
      <div v-if="isJackpot" class="season-points">
        <img width="24" src="/public/img/icons/jackpotPoints.svg" alt="" />
        <span>{{ BigNumber(jackpotScoreInYear || 0).toNumber() || convertSeasonPlaceToJackpotScore(position) }}</span>
      </div>

      <div v-if="userScores?.rewards && !$device.isMobile && !isJackpot" class="rewards">
        <img class="chest" src="/public/img/icons/chest.svg" alt="" />

        <div v-for="(reward, index) in [...userScores.rewards].reverse()" :key="index" class="reward-item">
          <img
            :src="tokens[tokenMetaData(blockchain.contracts, reward.tokenAddress).name.contract as Tokens].toString()"
            :alt="tokenMetaData(blockchain.contracts, reward.tokenAddress).label"
          />

          <span>{{ reward.amount }}</span>
        </div>
      </div>

      <div
        v-if="userScores?.rewards && ($device.isMobile || isJackpot) && BigNumber(checkedHistoricalSeason!).isNaN()"
        class="prize"
      >
        <el-tooltip placement="bottom-end" popper-class="customPopperLeaderboardPrize">
          <template #content>
            <div class="rewards" :style="{ margin: isJackpot || $device.isMobile ? 0 : '3px 16px 3px 0' }">
              <div v-for="(reward, index) in [...userScores.rewards].reverse()" :key="index" class="reward-item">
                <img
                  :src="
                    tokens[tokenMetaData(blockchain.contracts, reward.tokenAddress).name.contract as Tokens].toString()
                  "
                  :alt="tokenMetaData(blockchain.contracts, reward.tokenAddress).label"
                />

                <span>{{ reward.amount }}</span>
              </div>
            </div>
          </template>
          <img class="chest" style="margin-left: 15px" width="24" src="/public/img/icons/jackpotPrize.svg" alt="" />
        </el-tooltip>
      </div>

      <div v-if="!isJackpot || !BigNumber(checkedHistoricalSeason!).isNaN()" class="season-points">
        <img width="24" :src="tokens.seasonPoints" alt="" />
        <span>{{ score }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import tokens from '~/public/img/tokens';
import type { SeasonsUserScores } from '~/types/season';
import { tokenMetaData } from '~/utils';
import type { Tokens } from '~/utils/constants';
import useEnvs from '~/composables/useEnvs';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { BigNumber } from 'bignumber.js';
import { USER_NAME_MAX_LENGTH } from '~/utils/constants/profile';

const { blockchain } = useEnvs();
const { address: walletAddress } = useWeb3ModalAccount();

const el = ref<HTMLElement | null>(null);
const currentScrollTop = ref(0);
const currentScrollDirection = ref('');
const intersectionObserver = ref<IntersectionObserver>();

const props = defineProps<{
  score: number;
  position: number;
  own: boolean;
  userScores?: SeasonsUserScores;
  leaderboardLength: number;
  isJackpot: boolean;
  checkedHistoricalSeason?: number;
  jackpotScoreInYear?: number;
}>();

const emit = defineEmits<{
  changeShowStickyBgTop: [isShowStickyBgTop: boolean];
  changeShowStickyBgBottom: [isShowStickyBgBottom: boolean];
}>();

const userName = computed(() => {
  const address = props.userScores?.address;
  const userName = props.userScores?.name;

  if (address && userName && props.userScores.address && userName.length > USER_NAME_MAX_LENGTH) {
    return formatAddress(address);
  }

  return userName;
});

const isUserNameRuLocale = computed(() => props.userScores?.name && /^[А-Яа-я]/.test(props.userScores.name));

defineExpose({ el });

const ownPositionClickHandler = () => {
  if (!props.own) return;
  el.value?.nextElementSibling?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

watch(
  [walletAddress, () => props.own, el],
  () => {
    intersectionObserver.value?.disconnect();
    emit('changeShowStickyBgBottom', false);
    emit('changeShowStickyBgTop', false);

    const actualElement =
      props.position < props.leaderboardLength
        ? (el.value?.nextElementSibling as HTMLElement)
        : (el.value?.previousElementSibling as HTMLElement);
    const parentScrollContainer = actualElement?.parentNode?.parentNode as HTMLElement;

    if (actualElement && props.own) {
      intersectionObserver.value = new IntersectionObserver(
        (entries) => {
          if (parentScrollContainer.scrollTop === 0) {
            currentScrollDirection.value = '';
          } else if (currentScrollTop.value > parentScrollContainer.scrollTop) {
            currentScrollDirection.value = 'down';
          } else {
            currentScrollDirection.value = 'up';
          }
          currentScrollTop.value = parentScrollContainer.scrollTop;

          if (entries[0].intersectionRatio < 1 && currentScrollDirection.value === 'up' && !entries[0].isIntersecting) {
            emit('changeShowStickyBgTop', true);
          } else {
            emit('changeShowStickyBgTop', false);
          }

          if (
            entries[0].rootBounds &&
            ((entries[0].intersectionRect.top === 0 &&
              !entries[0].isIntersecting &&
              currentScrollDirection.value === '' &&
              entries[0].intersectionRatio === 0) ||
              (entries[0].intersectionRatio < 1 &&
                currentScrollDirection.value === 'down' &&
                !entries[0].isIntersecting))
          ) {
            emit('changeShowStickyBgBottom', true);
          } else {
            emit('changeShowStickyBgBottom', false);
          }
        },
        {
          threshold: [0.25, 0.75, 1],
          root: el.value?.parentNode?.parentNode as HTMLElement,
          rootMargin: '0px 0px -20px 0px'
        }
      );

      intersectionObserver.value.observe(actualElement as HTMLElement);
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.rewards {
  display: flex;
  gap: 16px;
  height: 34px;
  min-height: 34px;
  flex-direction: row-reverse;
  border-radius: 12px;
  padding: 2px 16px;
  background: rgb(7, 24, 33);
  background: linear-gradient(90deg, rgba(7, 24, 33, 1) 0%, rgba(4, 14, 19, 1) 35%);

  .chest {
    order: 1;
    width: 28px;
    max-width: 28px;
    max-height: 28px;
  }

  .reward-item {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      line-height: 20px;
      font-size: 20px;
      font-weight: 500;
      color: white;
      opacity: 0.5;
      z-index: 0;
    }

    img {
      height: 24px;
      min-height: 24px;
      min-width: 24px;
    }
  }
}

.leaderboardItem {
  height: 40px;
  border: 1px solid #3b7285;
  border-radius: 12px;
  padding: 0 24px;
  margin: 0 15px;
  background: linear-gradient(to bottom, #17343e 0%, #010303 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    padding: 0 12px;
  }

  &.ownPositionBG {
    background: linear-gradient(to bottom, #0083ad 0%, #00354f 100%);

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background: radial-gradient(120% 100% at 50% 100%, rgba(0, 131, 173, 1), rgba(0, 0, 0, 0) 50%),
          linear-gradient(to bottom, #17343e 0%, #010303 100%);
      }
    }

    .reward-item span {
      opacity: 1;
    }
  }

  .score,
  .address {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .address {
    span {
      color: #90989d;
      font-size: 16px;
      font-family: Eczar, sans-serif;

      &:first-child {
        color: #eea92e;
        font-size: 16px;
      }
    }

    .user-name {
      &.ownPositionRow span {
        color: #fff;
      }
    }

    .user-name span {
      color: #90989d;
      margin-left: 10px;
    }
  }

  .score {
    .season-points {
      display: flex;
      align-items: center;
      color: #eea92e;
      font-size: 22px;
      gap: 5px;

      @media screen and (max-width: 560px) {
        font-size: 20px;
      }

      &:last-child {
        margin-left: 15px;
      }
    }
  }
}

.own-background {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 60px;
  background: #040e13;
  z-index: -1;
}

.prize {
  display: flex;
  align-items: center;
}
</style>

<style>
.el-popper.is-dark.customPopperLeaderboardPrize {
  background-color: transparent;
  padding: 0;
  border-radius: 12px;
  border: none;
}
</style>
